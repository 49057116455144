import React, { useState } from 'react';
import UsersTable from './UsersTable';
import PreRegistrationTable from './PreRegistrationTable';
import { UserContextProvider } from '../../app/userContext';
import ApproveUsersTable from './ApproveUsersTable';
import MainTable from './MainTable';

export default function Users({ admin }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  let table;
  if (window.location.pathname === '/users')
    table = (
      <div className="h-full">
        <UsersTable
          admin={admin}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </div>
    );
  else if (window.location.pathname === '/pre-registration') {
    table = (
      <div className="h-full">
        <PreRegistrationTable admin={admin} />
      </div>
    );
  } else if (window.location.pathname === '/all-users') {
    table = (
      <div className="h-full">
        <MainTable
          admin={admin}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </div>
    );
  } else if (window.location.pathname === '/users-to-activate') {
    table = (
      <div className="h-full">
        <ApproveUsersTable
          admin={admin}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </div>
    );
  } else {
    table = null;
  }

  return (
    <div className="h-full">
      <UserContextProvider>{table}</UserContextProvider>
    </div>
  );
}
