import React from 'react';
import { Outlet } from 'react-router-dom';
import TitleBar from './TitleBar';
import SideBar from './SideBar';

export default function PageLayout({ admin }) {
  return (
    <div className="h-screen w-screen flex overflow-hidden">
      <div className="w-168 h-full">
        <SideBar admin={admin} />
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <TitleBar />
        <main className="flex-1 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
